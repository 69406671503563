<style scoped src="./index.less" lang="less" rel="stylesheet/less"></style>
<template src="./index.html"></template>
<script>
import mixinPage from "../../libs/mixinPage";
export default {
    mixins: [mixinPage],
    components: {},
    name: "help",
    data() {
        return {
            pageName: this.$options.name,
            keywords: "",
            list: [
                {
                    title:"送餐机器人一个顶10人 厦门餐饮业智能化突显",
                    url:"news200926",
                    img:require('@/images/news/20200922185610.png'), 
                    day:"26",
                    date:"2020-09",
                    alt:"在近日举行的2020年厦门餐饮行业产品对接会上，餐饮行业智能化更加突显。数个不同品牌、不同功能的送餐机器人穿梭在会场，既提供服务，也作为展示，托盘里摆放着各种点心、饮料等供人取用；美团智能收银系统也吸引众多餐饮商家咨询。"
                },
                {
                    title:"机器人与七夕的微妙联系",
                    url:"news200925",
                    img:require('@/images/news/WX20200926-135728.png'), 
                    day:"25",
                    date:"2020-09",
                    alt:"七夕佳节，必定少不了【吃饭、逛街】。到餐厅入座扫码点单后，给你上餐的是机器人，是不是很惊奇！到大型商场逛街，给你导引营销的是机器人，是不是又一脸震惊，感觉AI机器人与七夕撞了个满怀啊。"
                }
            ],
        };
    },
    methods: {
        
        goto(item_) {
            this.$router.push({ name: item_.url});
        },
    },
    mounted() {

    },
    activated() {},
};
</script>



